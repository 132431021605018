// components/seo/MetaHead.js
import React from "react";
import Head from "next/head";

const MetaHead = React.memo(({ title, description, url, image }) => {
	return (
		<Head>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:url" content={url} />
			<meta property="og:type" content="website" />
			<meta property="og:image" content={image} />
			<meta property="twitter:title" content={title} />
			<meta property="twitter:description" content={description} />
			<meta property="twitter:image" content={image} />
			<meta name="twitter:card" content="summary_large_image" />
			<link rel="canonical" href={url} />
		</Head>
	);
});

MetaHead.displayName = "MetaHead";

export default MetaHead;
